import React from 'react';
import ReactDOM from 'react-dom';
import './bootstrap.js';
import './index.css';
import * as serviceWorker from './serviceWorker';

const FallbackApp = props => (
    <div>
        <h1>No app configured</h1>
        <h2>Available apps:</h2>
        <ul>
            <li><code>cf-vital</code></li>
        </ul>
        <p>Please set the environment variable REACT_APP_ROOT and restart...</p>
    </div>
)

console.log('env vars', process.env)

const App = React.lazy(() => {
    let appRoot = process.env.REACT_APP_ROOT;
    console.log('Starting React App', appRoot);

    switch(appRoot) {
        case 'cf-vital':
            return import('./cf-vital/App');
        default:
            console.log('... unknown REACT_APP_ROOT', appRoot, ' serving ./App instead')
            return Promise.resolve({default: FallbackApp});
    }
});

ReactDOM.render((
        <React.Suspense fallback={<div>loading...</div>}>
            <App />
        </React.Suspense>
    ),
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
